<template>
	<div id="tu-message">
		<el-row :gutter="20">
			<el-col :span="4">
		    <SecondMenu type="1" />
			</el-col>
			<el-col :span="20">
				<!-- <div class="tu-title">
					<div>Messages</div>
					<div></div>
				</div> -->
				<div v-if="tableData.length > 0">
					<el-table 
					v-loading="loading"
					element-loading-text="Loading..."
					:data="tableData" 
					border 
					:show-header="showHeader">
					<!-- <el-table-column type="selection" width="35" align="center" /> -->
				    <el-table-column prop="content" :label="$t('Content')">
				    	<template #default="scope">
								<div @click="handleDetail(scope.$index, scope.row)" class="tu-item">
									<div v-if="!scope.row.is_reading" class="dot"></div>
									<div>
										<div class="title">{{ scope.row.content.title || '' }}</div>
										<div class="desc">{{ scope.row.content.desc || '' }}</div>
									</div>
								</div>
				    	</template>
				    </el-table-column>
				    <el-table-column prop="date" :label="$t('date')" width="220">
				    	<template #default="scope">
				    		<div>{{ handleCreatedTime(scope.row.created_at) }}</div>
				    	</template>
				    </el-table-column>
					</el-table>	
					<div class="tu-pagination">
    				<el-button type="primary" size="small" @click="onAllReaded">{{ $t('message.all-read') }}</el-button>
						<!-- <el-button type="primary" size="small">选择已读</el-button> -->
						<el-pagination 
							:hide-on-single-page="true" 
							background 
							layout="prev, pager, next" 
							:page-size="20"
							:total="params.total"
							@current-change="handlePageChange">
						</el-pagination>
					</div>
				</div>
				<div v-else>
					<el-empty :description="$t('No Data')"></el-empty>
				</div>
			</el-col>
		</el-row>
		<el-drawer
      :title="$t('router.message')"
      v-model="drawer"
      direction="rtl"
      :before-close="handleClose"
      custom-class="tu-drawer"
    >
      <div style="padding: 5px 15px;" v-if="message">
        <div style="font-size: 18px; font-weight: 600; padding: 5px 0">{{ message.title }}</div>
        <div style="font-size: 13px; color: var(--el-color-info);">{{ message.created_at }}</div>
        <div style="padding: 10px 0;">{{ message.desc }}</div>
      </div>
    </el-drawer>
	</div>
</template>
<script>
import SecondMenu from '@/components/SecondMenu'
import MessageApi from '@/api/message'
import TimeControl from '@/utils/time-control'

export default {
	name: "message",
	components: {
		SecondMenu
	},
	data() {
		return {
			drawer: false,
			loading: true,
			showHeader: false,
			tableData: [],
			message: [],
			params: {
				page: 1,
				total: 0
			}
		}
	},
	created() {
		this.getApiListData()
	},
	methods: {
		// 处理已读、未读操作
		handleDetail(index, row) {
			let rowId = row.id
			// 弹框展示信息数据
			this.message = row.content;
			this.message.created_at = this.handleCreatedTime(row.created_at)
			this.drawer = true;
			if(!row.is_reading) {
				let params = [row.id];
				MessageApi.readed(params).then(res => {
					row.is_reading = !row.is_reading;
				})
			}
		},
		handleClose(done) {
			done();
		},
		handleCreatedTime(value) {
			return TimeControl.getFormatTime(value, 'llll');
		},
		handlePageChange(page) {
			this.loading = true
			this.params.page = page || 1;
			this.getApiListData();
		},
		getApiListData() {
			MessageApi.index(this.params).then((res) => {
				this.loading = false
				this.tableData = res.data
				this.params.total = res.meta.pagination.total
			});
		},
		onAllReaded() {
			MessageApi.readed({all: 1}).then(res => {
				this.handlePageChange(1)
			})
		}
	}
}
</script>
<style lang="less">
#tu-message {
	.tu-title {
		background: var(--el-color-primary);
	  padding: 17px 15px;
	  color: var(--el-color-white);
	  text-align: left;
	  font-weight: 700;
	  display: flex;
	  flex-direction: row;
	  justify-content: space-between;
	  align-items: center;
	}

	.tu-pagination {
		padding: 20px 0;
		display: flex;
    align-items: center;
    flex-direction: row;
	}

	.tu-drawer {
	  .el-drawer__body {
	    padding: 0;
	  }
	}

	.tu-item {
		display: flex;
    align-items: baseline;
    cursor: pointer;

		.dot {
			width: 8px;
	    height: 8px;
	    background: var(--el-color-danger);
	    border-radius: 50%;
	    margin-right: 8px;
		}

		.title {
			font-size: 16px;
			font-weight: 600;
			color: var(--el-color-black);
		}

		.desc {
			font-size: 13px;
		}
	}
}
</style>